<template>
<charities-table/>
</template>

<script>
import CharitiesTable from '@/views/employee/components/CharitiesTable'
export default {
  name: 'EmployeeCharities',
  components: { CharitiesTable }
}
</script>

<style scoped>

</style>
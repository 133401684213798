<template>
  <v-card class='rounded-lg ma-5' elevation='3'>
    <v-card-title class='headline'>
      <v-col cols='12' lg='7' md='7' sm='12' xl='7' xs='12'>
        {{ sections.charities || 'Charities' }}
      </v-col>
    </v-card-title>

    <v-data-table
      :headers='charitiesHeaders'
      :items='activeCharities'
      :items-per-page='10'
      :loading='loading'
      :options.sync='charitiesTableMeta'
      :server-items-length='charitiesNumber'
      class='elevation-1 data-table-border-radius tableCustom'
      item-key='id'
      loading-text='Loading charities... Please wait'
      @update:options='getAllCharities'
    >
      <!--      <template #item.logo={item}>-->
      <!--        <v-avatar color='grey' size='80'>-->
      <!--          <v-img :src='item.logo'></v-img>-->
      <!--        </v-avatar>-->
      <!--      </template>-->
      <template #item.name={item}>
        <v-avatar class='mr-6 my-2' color='grey' size='75'>
          <v-img :src='item.logo'></v-img>
        </v-avatar>
        <span class='charityName' @click='redirect(item)'>{{ item.name }}</span>
      </template>
      <template #item.actions={item}>
        <v-btn
          color='primary'
          depressed
          small
          @click='redirect(item)'
        >
          View charity
        </v-btn>
      </template>
    </v-data-table>
  </v-card>

</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CharitiesTable',
  data: () => ({
    charitiesHeaders: [
      {
        text: 'Charity',
        align: 'start',
        value: 'name',
        width: '30%',
        divider: true
      },
      {
        text: 'Mission',
        value: 'mission',
        width: '50%',
        divider: true
      },
      {
        text: 'Actions',
        align: 'center',
        value: 'actions',
        width: '20%',
        divider: true
      }
    ],
    charitiesTableMeta: {},
    charitiesNumber: null,
    loading: false
  }),
  async created() {
    await this.getAllCharities()
  },
  computed: {
    ...mapState({
      activeCharities: state => state.charityStore.activeCharities,
      sections: state => state.cmsStore.sections
    })
  },
  methods: {
    ...mapActions('charityStore', ['getActiveCharities']),
    redirect(item) {
      this.$store.dispatch('charity', item.id)
      this.$router.push('/employee/charity-donations')
    },
    async getAllCharities() {
      this.loading = true
      const { page, sortBy, sortDesc, itemsPerPage } = this.charitiesTableMeta
      const params = {
        page,
        sort: sortBy?.[0],
        method: sortBy?.[0] ? (sortDesc?.[0] ? 'DESC' : 'ASC') : undefined,
        itemsPerPage: itemsPerPage === -1 ? this.charitiesNumber : itemsPerPage
      }
      const { meta: { total } } = await this.getActiveCharities(params)
      this.charitiesNumber = total
      this.loading = false
    }
  }
}
</script>
<style scoped>
.charityName {
  cursor: pointer;
}
</style>
